import React from 'react';
import { Helmet } from 'react-helmet';
// import { config } from 'config';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { vw } from '../../css/css.mixins.media';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const Container = styled.div`
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
`;

const PageWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;

	${vw.lv10`
    width: 40rem;
  `};
`;

const TextWrapper = styled.div`
	display: block;
	align-items: center;
	width: 100%;

	${vw.lv10`
    width: 40rem;
  `};
`;

const ResponsiveYouTubeVideo = styled.p`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

class VideoIndex extends React.Component {
	render() {
		const opts = {
			height: '390',
			width: '640',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 0
			}
		};

		const { site: { siteMetadata: { blogTitle } } } = this.props.data;

		return (
			<Container>
				<Helmet>
					<title>{blogTitle}</title>
				</Helmet>

				<PageWrapper>
					<TextWrapper>
						<div
							className="markdown"
							style={{
								display: 'block'
							}}
						>
							<h1>Vlog</h1>

							<ResponsiveYouTubeVideo>
								<YouTube videoId="LThjq-s01T8" opts={opts} />
							</ResponsiveYouTubeVideo>

							<ResponsiveYouTubeVideo>
								<YouTube videoId="N8NOJn2ETDk" opts={opts} />
							</ResponsiveYouTubeVideo>

							<ResponsiveYouTubeVideo>
								<YouTube videoId="hcstAp_9LIE" opts={opts} />
							</ResponsiveYouTubeVideo>
						</div>
					</TextWrapper>
				</PageWrapper>
			</Container>
		);
	}
}

VideoIndex.propTypes = {
	route: PropTypes.object
};

export default VideoIndex;
export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				description
				authorName
				subTitle
				authorBio
				gaCode
				blogTitle
			}
		}
	}
`;
